import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';

const AnnualReports = ({ data }) => {
  const { report } = data;

  return (
    <Layout bgcolor={report.backgroundColor} wrapperClass="report" accentColor={report.accentColor}>
      <HelmetDatoCms seo={report.seoMetaTags} />
      <div className="report-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{report.title}</h1>
          {report.featuredImage && (
            <Img fluid={{ ...report.featuredImage.fluid }} alt={report.featuredImage.alt} className="report-image" />
          )}
          {report.featuredImage.title && (
            <p
              className="caption"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: report.featuredImage.title,
              }}
            />
          )}
          <div
            className="sans--regular"
            dangerouslySetInnerHTML={{
              __html: report.description,
            }}
          />
          <div className="report-collection row">
            {report.reports.map(
              ({ file, title }) => (
                <div
                  className="col-xs-7 report"
                >
                  <a href={file.url} target="_blank" rel="noreferrer" download={title}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="19" stroke="black" strokeWidth="2" />
                      <path d="M11.5791 23.1578L20.0002 31.5789L28.4212 23.1578" fill="none" stroke="black" strokeWidth="2" />
                      <path d="M20 7.36841L20 31.5789" fill="none" stroke="black" strokeWidth="2" />
                    </svg>
                    <div className="text-wrapper">
                      <p style={{ marginBottom: '0' }}>{title}</p>
                      <p style={{ textDecoration: 'underline' }}>Download pdf</p>
                    </div>
                  </a>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
      <div className="divider container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={report.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

AnnualReports.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default AnnualReports;

export const query = graphql`
  query ReportQuery {
    report: datoCmsAnnualReport {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      title
      featuredImage {
        alt
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        title
      }
      description
      reports {
        ... on DatoCmsReport {
          file {
            url
          }
          title
        }
      }
    }
  }
`;
